import moment from 'moment';//时间
import { $api } from '@/config/api';

/* 中国标准时间 转 正常时间 */
export const FORMATTIME = (val) => {
  return val ? moment(val).format('YYYY-MM-DD HH:mm:ss') : ''
}

export const handleHoverImg = () => {
  // 获取页面上hoverImg下所有的img
  var imageSrc = document.querySelectorAll('.hoverImg img');
  if (imageSrc && imageSrc.length) {
    imageSrc.forEach(item => {
      item.src = handleSrcContent(item.src)
    })
  }
  return imageSrc
}


export const handleZoomImg = () => {
  var imageSrc = document.querySelector('.zoomImg');
  if (imageSrc && imageSrc.src) {
    imageSrc.src = handleSrcContent(imageSrc.src)
  }

  return imageSrc
}


export const handleSetHtml = (value, flag) => {
  console.log('处理富文本数据---->', value, flag)
  // 正则表达式匹配所有的img标签
  let imgRegex = /<img[^>]+src="([^">]+)"/g;
  let imageRegex = /<image[^>]+src="([^">]+)"/g;

  // 使用正则表达式找到所有的图片链接
  let matchs = [];

  while ((matchs = imgRegex.exec(value)) !== null) {
    let newSrc = ''
    console.log('matchs[1]----->', matchs[1])

    if (flag == 'edit') {
      // 详情的时候使用
      newSrc = handleSrcContent(matchs[1]);
    } else {
      // 提交的时候使用
      newSrc = handleDelContent(matchs[1]);
    }

    value = value.replace(matchs[1], newSrc);

  }
  while ((matchs = imageRegex.exec(value)) !== null) {
    let newSrc = ''

    if (flag == 'edit') {
      // 详情的时候使用
      newSrc = handleSrcContent(matchs[1]);
    } else {
      // 提交的时候使用
      newSrc = handleDelContent(matchs[1]);
    }

    value = value.replace(matchs[1], newSrc);
  }
  console.log('处理数据--->', value);
  return value
}



export const handleDelContent = (url) => {
  let urlArr = []
  let urls = []
  let typeofUrl = 'string'


  if (url) {
    if (typeof url === 'string') {
      typeofUrl = 'string'
      // 处理原始数据----->
      urlArr = url.split(',')
    } else {
      typeofUrl = 'array'
      urlArr = url
    }
    urlArr.map(item => {
      if (item.indexOf('requestId=') != -1 && (item.indexOf('file.marathonbm.com') != -1 || item.indexOf("marathon-image.hpaopao.com") != -1 || item.indexOf("static.hpaopao.com") != -1 || item.indexOf("marathon-web.hpaopao.com") != -1 || item.indexOf("paopao-image.hpaopao.com") != -1 || item.indexOf("psq-img.hpaopao.com") != -1)) {
        if (item.indexOf('?') !== -1) {
          // 有问号
          urls.push(`${item.split('?requestId=')[0]}`)
        } else {
          // 没有问号
          urls.push(`${item.split('&requestId=')[0]}`)
        }
      } else {
        urls.push(item)
      }
    })
  }

  if (typeofUrl == 'string') {
    return urls.toString()
  } else {
    return urls
  }
}



export const handleSrcContent = (url) => {
  let urlArr = []
  let urls = []
  let typeofUrl = 'string'


  if (url) {

    let array = ['982285707324166266', '982285927139250176', '982285927139250181', '982285927139250186', '982285927139250178']
    var randomIndex = Math.floor(Math.random() * array.length);
    var srcNumber = array[randomIndex];

    if (typeof url === 'string') {
      typeofUrl = 'string'
      // 处理原始数据----->
      urlArr = url.split(',')
    } else {
      typeofUrl = 'array'
      urlArr = url
    }


    urlArr.map(item => {
      if (item.indexOf('requestId=') == -1 && (item.indexOf('file.marathonbm.com') != -1 || item.indexOf("marathon-image.hpaopao.com") != -1 || item.indexOf("static.hpaopao.com") != -1 || item.indexOf("marathon-web.hpaopao.com") != -1 || item.indexOf("paopao-image.hpaopao.com") != -1 || item.indexOf("psq-img.hpaopao.com") != -1)) {
        if (item.indexOf('?') !== -1) {
          urls.push(`${item}&requestId=${srcNumber}`)
        } else {
          urls.push(`${item}?requestId=${srcNumber}`)
        }
      } else {
        urls.push(item)
      }
    })
  }

  if (typeofUrl == 'string') {
    return urls.toString()
  } else {
    return urls
  }
}





/* 中国标准时间/毫秒值 转 正常时间 带时分秒 */
export const TIMEFORMATmin = (msec) => {
  let datetime = new Date(msec);
  // console.log(' 自定义时间转换 ', datetime)
  let year = datetime.getFullYear();
  let month = datetime.getMonth();
  let date = datetime.getDate();
  let hour = datetime.getHours();
  let minute = datetime.getMinutes();
  let second = datetime.getSeconds();

  let result1 = year +
    '-' +
    ((month + 1) >= 10 ? (month + 1) : '0' + (month + 1)) +
    '-' +
    ((date + 1) <= 10 ? '0' + date : date) +
    ' ' +
    ((hour + 1) <= 10 ? '0' + hour : hour) +
    ':' +
    ((minute + 1) <= 10 ? '0' + minute : minute) +
    ':' +
    ((second + 1) <= 10 ? '0' + second : second);


  return result1;
}


export const expressData = [
  {
    value: '顺丰速运',
    label: 'SF'
  },
  {
    value: '圆通快递',
    label: 'YTO'
  },
  {
    value: '圆通承诺达',
    label: 'CP468398'
  },
  {
    value: '韵达快递',
    label: 'YUNDA'
  },
  {
    value: '韵达快运',
    label: 'YDKY'
  },
  {
    value: '韵达同城',
    label: 'LE10032270'
  },
  {
    value: '中通快递',
    label: 'ZTO'
  },
  {
    value: '中通快运',
    label: 'ZTOKY'
  },
  {
    value: '申通快递',
    label: 'STO'
  },
  {
    value: '申通快运',
    label: 'STOKY'
  },
  {
    value: '百世快递',
    label: 'HTKY'
  },
  {
    value: '百世快运',
    label: 'BESTQJT'
  },
  {
    value: '百世云配',
    label: 'CP443514'
  },
  {
    value: '极兔快递',
    label: 'JT'
  },
  {
    value: '丰网速运',
    label: 'FW'
  },
  {
    value: '中国邮政',
    label: 'EMS'
  },
  {
    value: '邮政国际包裹',
    label: 'CNPOSTGJ'
  },
  {
    value: 'EMS国际',
    label: 'EMSGJ'
  },
  {
    value: '京东快递',
    label: 'JD'
  },
  {
    value: '德邦快递',
    label: 'DBKD'
  },
  {
    value: '德邦物流',
    label: 'DBL'
  },
  {
    value: '德邦快运',
    label: 'DBKY'
  },
  {
    value: '天天快递',
    label: 'TTKDEX'
  },
  {
    value: '宅急送',
    label: 'ZJS'
  },
  {
    value: '联邦快递',
    label: 'FEDEX'
  },
  {
    value: '苏宁快递',
    label: 'SNWL'
  },
  {
    value: '丹鸟',
    label: 'ZMKM'
  },
  {
    value: 'DHL中国',
    label: 'DHLCN'
  }
]



/* 中国标准时间/毫秒值 转 正常时间 不带时分秒 */
export const TIMEFORMAT = (msec) => {
  let datetime = new Date(msec);
  // console.log(' 自定义时间转换 ', datetime)
  let year = datetime.getFullYear();
  let month = datetime.getMonth();
  let date = datetime.getDate();

  let result2 = year +
    '-' +
    ((month + 1) >= 10 ? (month + 1) : '0' + (month + 1)) +
    '-' +
    ((date + 1) < 10 ? '0' + date : date);

  return result2;
}



export const SD = (val) => {
  return (val ?? '') !== '' ? val : '--';
}

export const ND = (val) => {
  return (val ?? '') !== '' ? val : '--';
}

// 校验两位小数
export const getTofixed = (numBer) => {
  let num = Number(numBer.toString().match(/^\d+(?:\.\d{0,2})?/));
  return num
}

//写一个公用存储页面参数的方法
export const getParams = (val, flag) => {
  let obj = [{
    url: window.location.pathname,
    params: val
  }]
  console.log('数据-----', localStorage.getItem('params'))
  let arr = localStorage.getItem('params') ? JSON.parse(localStorage.getItem('params')) : []
  let newArr = []
  if (arr.length) {
    // 之前有存储的数据 并且这个页面没有存储/或者允许替换！！！！！！
    let isItem = arr.find(item => {
      return item.url == window.location.pathname
    })
    console.log('查看之前是否有存储的数据', isItem, flag, arr)
    if (isItem && !flag) {
      newArr = [...arr]
    } else if (isItem && flag) {
      // 有存储 替换参数
      arr.map(item => {
        if (item.url == window.location.pathname) {
          item.params = val
        }
      })
      newArr = [...arr]
    } else {
      // 没有存储 添加参数+url
      newArr = arr.concat(obj)
    }
  } else {
    // 没有存储过数据
    newArr = [...obj]
  }
  localStorage.setItem('params', newArr ? JSON.stringify(newArr) : [])
  console.log('搜索的内容----', val, window.location.pathname, arr, newArr)
  let param = {}

  newArr.map(item => {
    if (item.url == window.location.pathname) {
      console.log('之前有存储的参数！！！', item.url)
      param = item.params
    }
  })
  // 抛出参数
  return param
}

// 销毁标签所存储的内容
export const closeParam = (val) => {
  let arr = localStorage.getItem('params') ? JSON.parse(localStorage.getItem('params')) : []
  console.log('存储---', arr)

  if (arr.length) {
    let isItem = arr.find(item => {
      return item.url == val
    })

    if (isItem) {
      let index = arr.findIndex(item => {
        return item.url == val
      })
      arr.splice(index, 1)
      console.log('index----', index, arr)

    }
    localStorage.setItem('params', arr ? JSON.stringify(arr) : [])

    console.log('是否有存储内容 并 销毁标签-----', isItem, arr)
  }


}


export const goBack = (path, NS, navigate) => {
  let arr = []
  let list = []

  JSON.parse(sessionStorage.getItem('navigateList')).map((item, index) => {
    console.log('数据-----', item.path, window.location.pathname.slice(1, window.location.pathname.length))
    if (item.path == window.location.pathname.slice(1, window.location.pathname.length)) {
      console.log('相同数据-----', item)
      localStorage.getItem('path')?.split(',')?.map(item => {
        NS.delList(`/${item.path}${window.location.search}`)
      })
    } else {
      list.push(item)
    }
  })
  localStorage.getItem('path')?.split(',')?.map((item, index) => {
    if (item.split('?')[0] == window.location.pathname) {

    } else {
      arr.push(item)
    }
  })
  NS.navigateList = list
  sessionStorage.setItem('navigateList', JSON.stringify(list))
  console.log('最终的数据-----', arr, list)
  localStorage.setItem('path', arr.toString())
  localStorage.setItem('flagPath', true)

  navigate(path)
  localStorage.removeItem('flagPath')
}
