import {Tree} from 'antd';
import {Menu, Item, useContextMenu} from 'react-contexify';
import 'react-contexify/dist/ReactContexify.min.css';

const TreeRightProps = {
    dataSource: [],
    contextMenu: "",
    onSelect: (selectedKeys, info) => {},
};

const TreeRight= (props) => {
    const {dataSource,contextMenu,onSelect}=props
    const ContextMenu = ()=>(
        <Menu id={contextMenu.menuId} style={{'width':'88px','min-width':'88px'}}>
            {contextMenu.items.map(item =>(
                <Item onClick={item.handler} style={{'width':'88px','min-width':'88px'}}>{item.name}</Item>
            ))}
        </Menu>
    )

    const {show} = useContextMenu({
        id: contextMenu.menuId
    })

    const handleContextMenu = (event, node) => {
         event.preventDefault()
        console.log(event)
        show(event,{
           props: node
        })
    }

    const fieldName = {
        title: 'title', 
        children: 'children'
    }
    return (
        <div>
            <Tree 
               fieldNames={fieldName}
               treeData={dataSource}
               onSelect={onSelect}
               autoExpandParent={true}
               onRightClick={({event, node}) => {
                   handleContextMenu(event, node)
               }}
               />
               <ContextMenu/>
        </div>
    )
}
export default TreeRight