import { useState } from 'react'
import { Button } from 'antd';
const AuthButton = (props) => {
  const [loading, setLoading] = useState(false)
  // console.log('props', props)
  const handleLoading = () => {
    // console.log('buttonName', props.buttonName?.indexOf('上传'))
    let flag = (
      props.buttonName == '搜索'
      || props.buttonName == '重置'
      || props.buttonName == '取消'
      || props.buttonName == '返回'
      || (props.buttonName && props.buttonName?.indexOf('上传') != -1)
      || (props.buttonName && props.buttonName?.indexOf('新增') != -1)
      || (props.buttonName && props.buttonName?.indexOf('添加') != -1)
      || (props.buttonName && props.buttonName?.indexOf('取消') != -1)
      || (props.buttonName && props.buttonName?.indexOf('编辑表单') != -1)
      || props.children == '搜索'
      || props.children == '重置'
      || props.children == '取消'
      || props.children == '返回'
      || (props.children && props.children.indexOf('上传') != -1)
      || (props.children && props.children?.indexOf('新增') != -1)
      || (props.children && props.children.indexOf('添加') != -1)
      || (props.children && props.children.indexOf('取消') != -1)
      || (props.children && props.children.indexOf('编辑表单') != -1)
    )
      ? true
      : false

    if (flag) {
      // 父组件的点击事件！！！
      props.onClick()
    } else {
      if (props.onClick) {
        // 父组件的点击事件！！！
        props.onClick()

        // 进行加载
        setLoading(true)
        setTimeout(() => {
          setLoading(false)
        }, 2000)
      }

    }

  }
  return (
    <>
      <Button
        className={props.className}
        size={props.size}
        ghost={props.ghost}
        loading={loading}
        disabled={props.disabled}
        danger={props.danger}
        htmlType={props.htmlType}
        icon={props.icon}
        style={props.style}
        type={props.type}
        onClick={() => {
          handleLoading()
        }}>
        {/* 如果有传递buttonName值就按照buttonName来，如果没有就按照children的内容 */}
        {props.buttonName ? props.buttonName : (props.children ? props.children : '')}
      </Button>
    </>
  )
}

export default AuthButton;