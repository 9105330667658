import { $api } from '@/config/api'
import { useStore } from '@/store'
import { handleSrcContent } from '@/utils'
import { message } from 'antd'
import { observer } from 'mobx-react-lite'
import { useEffect, useRef, useState } from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
const Asides = () => {
  const { navigationStore: NS, routeStore: RS } = useStore()
  const RTLIST = useRef(RS.rtList.filter(i => i.isShow))

  const [menu, setMenu] = useState([])
  const [count, setCount] = useState(0)//一级路由icon默认索引
  const [pathName, setPathName] = useState('')//一级路由切换path

  const [routeCount, setRouteCount] = useState(0)//二级路由icon默认索引
  const [routeName, setRouteName] = useState('')//二级路由切换列表path

  const [warnCount, setWarnCont] = useState([
    {
      path: '/account-checking',
      count: 2
    },
    {
      path: '/information',
      count: 200
    },
    {
      path: '/overview',
      count: 33
    }
  ])

  const navigate = useNavigate()//跳转hook
  // 点击一级路由icon
  const clickTab = (index, item) => {
    console.log(item, index)
    setPathName(item.href)//修改一级路由保存的path信息
    setRouteCount(index)//修改二级路由索引  二级路由通过一级路由的索引渲染出对应的二级路由
    setWarnCont(
      [...warnCount],
      warnCount[1].count = 3
    )
  }

  // 点击二级列表
  const clickRoute = (routeIndex, routeItem) => {
    console.log('二级列表-----点击', routeIndex, routeItem, menu[routeCount])
    setRouteName(routeItem.href)//修改保存的二级路由path
    navigate(routeItem.href)//跳转
    document.title = routeItem.title

    // 把当前点击导航记录下来 如果有子导航  直接在后面追加文字就好了 navigationTitle
    NS.delNavigateChildrenList(true)
    NS.addNavigateChildrenList(routeItem.title)
    // console.log('NS', NS.navigateChildrenList)
    // console.log('点击的路由', routeItem)
    // NS.addList(routeItem)
    // NS.refreshView()
  }

  const location = useLocation()//获取路由信息hook

  useEffect(() => {
    if (localStorage.getItem('token')) {
      $api.menuLogin().then(res => {
        if (res.code == 200) {
          setMenu(res.data)
          if (location.pathname === '/') {
            navigate(res.data[0].children[0].href)
            return
          }
          setPathName(`/${location.pathname.split('/')[1]}`)
          setRouteName(location.pathname)
          //通过该方法找到path所在数据内的一级路由索引值 用于渲染二级路由 
          const routePath = `/${location.pathname.split('/')[1]}`
          console.log('res.data:', res.data)
          const idx = res.data.findIndex(i => i.href === routePath)
          console.log(idx)
          //把索引添加到二级路由里面 二级路由通过一级路由的索引渲染出对应的二级路由
          setRouteCount(idx == -1 ? 0 : idx)
          console.log('routePath:', routePath, location, location.search)

          //设置title
          const title = res.data[idx]?.children.filter(i => i.title === location.title)[0]
          document.title = title ? title.title : ''
        } else {
          message.error('')
          navigate('/login')
        }
      })
    } else {
      //message.error('请重新登录')
      navigate('/login')

    }


  }, [location.pathname, navigate])

  return (
    <>
      <div className="asideContentLeft">
        <ul>
          {
            menu.map((item, index) => (
              <li key={item.id} className={`asideBtns ${item.href === pathName ? 'asideBtnsActive' : null}`}
                onClick={(e) => clickTab(index, item, e)}
              >
                {item.icon
                  ? <img src={handleSrcContent(item.icon)} alt='' />
                  : ''
                }
                <span className='asideBtnsTxt'>{item.title}</span>
              </li>
            ))
          }
        </ul>
      </div>

      <div className="asideContentRight">
        <ul>
          {menu[routeCount]?.children.length ?
            menu[routeCount]?.children.map((routeItem, routeIndex) => (
              <li key={routeItem.id} className={`routeName ${routeItem.href === routeName || routeItem.href === `/${location.pathname.split('/')[1]}/${location.pathname.split('/')[2]}` ? 'routeNameActive' : null}`}
                onClick={(e) => clickRoute(routeIndex, routeItem, e)}
              >
                <span></span>
                {routeItem.title}
              </li>
            ))
            : <li>
              {count}
            </li>
          }
        </ul>
      </div>
    </>
  );
}

export default observer(Asides);