import { useStore } from '@/store';
import { observer } from 'mobx-react-lite'
import { useEffect, useRef, useState, useImperativeHandle } from 'react';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Modal, Button, Tree, TreeSelect, Input, Form, Upload, message, Space, Pagination, Checkbox } from 'antd';
import { EnvironmentOutlined, DeleteOutlined, UploadOutlined, MinusCircleOutlined, PlusOutlined, ArrowUpOutlined, ArrowDownOutlined, EditOutlined, CloseOutlined, MinusOutlined, CheckOutlined } from '@ant-design/icons';
import { $api } from '@/config/api';
import 'react-contexify/dist/ReactContexify.min.css';
import TreeRight from './TreeRight';
import { handleSrcContent } from '@/utils'
import { post } from '@/config/request';
import { val, value } from 'dom7';
import Cropper from "react-cropper";
import 'cropperjs/dist/cropper.css' // 引入Cropper对应的css
import './style/FileUpload.less'
import AuthButton from '@/components/AuthButton';

const { confirm } = Modal;

const { TreeNode } = Tree;

const FileUpload = (props) => {
  const [modalTitle, setModalTitle] = useState('')
  const closeIcon = require('@/assets/images/close.png')
  const nopicIcon = require('@/assets/images/nopic.png')
  const docPic = require('@/assets/images/doc.png')
  const noSelect = require('@/assets/images/noselect.png')
  const htmlPic = require('@/assets/images/html.png')
  const pdfPic = require('@/assets/images/pdf.png')
  const xlsPic = require('@/assets/images/xls.png')
  const normalPic = require('@/assets/images/nomal.png')

  const [pics, setPics] = useState([])
  const [pValue, setPValue] = useState('0')
  const [showEmpty, setShowEmpty] = useState(false)
  const [pId, setPId] = useState(0)
  const [cId, setCid] = useState('')
  const [name, setName] = useState('')
  const [fileSize, setFileSize] = useState('0MB')
  const [totalSize, setTotalSize] = useState('0GB')
  const [treeData, setTreeData] = useState([])

  const [positions, setPositions] = useState({})//鼠标位置
  const [onRightNodeData, setOnRightNodeData] = useState()//点击右键暂存数据
  const [showRightPanel, setShowRightPanel] = useState(false)//是否显示右键面板
  const [totalPageSize, setTotalPageSize] = useState(0)
  const [curPage, setCurPage] = useState(1)
  const [pageSize, setPageSize] = useState(20)
  const [srcImageUrl, setSrcImageUrl] = useState('')

  const [form] = Form.useForm();

  const [isDelFile, setIsDelFile] = useState(false)

  const dealTreeData = (data) => {
    let loopNode = (_data) => {
      for (let j = 0; j < _data.children.length; j++) {
        _data.children[j]['key'] = _data.key + '-' + j
        _data.children[j]['value'] = _data.children[j]['title']
        _data.children[j]['defaultValue'] = _data.children[j]['title']
        if (_data.children[j] && _data.children[j].children && _data.children[j].children.length) {
          loopNode(_data.children[j])
        }
      }
    }
    if (data && data.length) {
      for (let i = 0; i < data.length; i++) {
        data[i]['key'] = i + ''
        data[i]['value'] = data[i].title
        data[i]['defaultValue'] = data[i].title
        if (data[i] && data[i].children && data[i].children.length) {
          loopNode(data[i])
        }
      }
    }
    return data
  }

  const [isMoreSelect, setIsMoreSelect] = useState(false)

  const [currentId, setCurrentId] = useState(-1)
  const [maxCount, setMaxCount] = useState(1)

  useEffect(() => {
    if (props.params.maxCount) {
      setMaxCount(props.params.maxCount ? props.params.maxCount : 1)
    }
    if (props.params.file == 'image' && !props.params.width) {
      setIsMoreSelect(true)
    }
    if (props.params.width > 0 && props.params.height > 0) {
      setModalTitle(' (最小宽度' + props.params.width + '，最小高度' + props.params.height + '，大小不超过600KB' + ')')
    }
    if (props.params.minWidth && props.params.minHeight) {
      setModalTitle(' (最大宽度' + props.params.minWidth + '，最大高度' + props.params.minHeight + '，大小不超过600KB' + ')')
    }
    initData()
  }, [])

  const initData = async () => {
    console.log('++++++++' + localStorage.getItem('cpid'))
    const reqData = await $api.mainAlbum({ id: localStorage.getItem('cpid') })
    setCurrentId(reqData.data.id)
    let resTreeData = await dealTreeData([reqData.data])
    setTreeData(await resTreeData)
    onChangePage(1, reqData.data.id)
  }

  const onChangePageNumber = (pageNumber, pageSize) => {
    onChangePage(pageNumber, currentId)
  }

  const onChangePage = (pageNumber, id) => {
    console.log('传递的id -==', id)
    //接口获取图片
    let params = {
      'id': id,
      'pageNo': pageNumber,
      'rows': pageSize
    }
    $api.albums(params).then(r => {
      if (r.code == '200') {
        if (showEmpty == false) {
          setShowEmpty(true)
        }
        setPics(r.data)
        setTotalPageSize(r.total)
        setCurPage(pageNumber)
        setCheckedList([])
      }
    })
  }

  //点击树节点
  const handleSelect = (selectedKeys, info) => {
    if (info.selectedNodes[0]?.id) {

      console.log('当前点击的 - id : ', info.node.id)
      setCurrentId(info.node.id)
      onChangePage(1, info.node.id)
    }
  }

  //右键菜单点击事件。props里就是节点数据
  const handleItemClick = (type, item) => {
    if (type === 'del') {
      delNode(item)
    } else {
      onEdit(item.props.key)
    }
  }

  const onEdit = (key) => {
    editNode(key, treeData);
    setTreeData(treeData.slice())
  };

  const editNode = (key, data) =>
    data.forEach((item) => {
      if (item.key === key) {
        item.isEditable = true;
      } else {
        item.isEditable = false;
      }
      item.value = item.defaultValue; // 当某节点处于编辑状态，并改变数据，点击编辑其他节点时，此节点变成不可编辑状态，value 需要回退到 defaultvalue
      if (item.children) {
        editNode(key, item.children);
      }
    });

  const onClose = (key, defaultValue) => {
    closeNode(key, defaultValue, treeData);
    setTreeData(treeData.slice());
  };

  const closeNode = (key, defaultValue, data) =>
    data.forEach((item) => {
      item.isEditable = false;
      if (item.key === key) {
        item.value = defaultValue;
      }
      if (item.children) {
        closeNode(key, defaultValue, item.children);
      }
    });

  const treeRightMenu = {
    menuId: '1',
    items: [
      { key: 'edit', name: '重命名', handler: (props) => { handleItemClick('edit', props) } },
      { key: 'del', name: '删除', handler: (props) => { handleItemClick('del', props) } },
    ]
  }

  const onChangeInput = (e, key) => {
    changeNode(key, e.target.value, treeData);
    setTreeData(treeData.slice());
  }
  const changeNode = (key, value, data) =>
    data.forEach((item) => {
      if (item.key === key) {
        item.value = value;
      }
      if (item.children) {
        changeNode(key, value, item.children);
      }
    });


  const onSave = (e, item) => {
    if (e.keyCode == 13) {

      const obj = {
        id: item.id,
        title: item.value
      }
      $api.updateAlbum(obj).then(r => {
        if (r.code === 200) {
          // 修改成功关闭input
          saveNode(item.key, treeData);
          setTreeData(treeData.slice());
          message.success('修改成功')
        } else {
          message.error('修改失败')
        }
      })
    }
  };
  const saveNode = (key, data) =>
    data.forEach((item) => {
      if (item.key === key) {
        item.defaultValue = item.title;
      }
      if (item.children) {
        saveNode(key, item.children);
      }
      item.isEditable = false;
    });

  const renderTreeNodes = (data) => {
    let nodeArr = data.map((item) => {
      if (item.isEditable) {
        item.title = (
          <div>
            <input value={item.value} onChange={(e) => onChangeInput(e, item.key)} onBlur={() => { onClose(item.key, item.defaultValue) }}
              onKeyUp={(e) => { onSave(e, item) }}
              ref={input => input && input.focus()} />
          </div>
        );
      } else {
        item.title = (
          <div>
            <span >{item.value}</span>
          </div>
        );
      }
      if (item.children) {
        return (
          <TreeNode title={item.title} key={item.key} dataRef={item}>
            {renderTreeNodes(item.children)}
          </TreeNode>
        );
      }

      return <TreeNode title={item.title} key={item.key} />;
    });

    return nodeArr;
  };

  const delNode = (item) => {
    let id = item.props.id
    confirm({
      title: '删除该文件夹及包含的所有图片？',
      icon: <ExclamationCircleOutlined style={{ 'color': '#fff', 'backgroundColor': 'red', 'borderRadius': '50%' }} />,
      content: '该操作不可撤销',
      okText: '确认删除',
      okType: 'danger',
      cancelText: '取消',
      onOk() {
        $api.delAlbum({ id: id }).then(r => {
          if (r.code == '200') {
            message.success(r.message);
          } else {
            message.fail(r.message);
          }
          initData()
        })
      },
      onCancel() {
      },
    });
  }
  const [isModalVisible, setIsModalVisible] = useState(false);

  const showModal = () => {
    setPValue('0')
    setName('')
    setPId(treeData[0].id)
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const onChangeFile = (key, node, extra) => {
    setPValue(node.key);
    setPId(node.id)
  };

  const onChangeName = (e) => {
    setName(e.target.value)
  }

  const onFinish = (values) => {
    let params = {
      'pid': pId,
      'scope': 'MC_SCOPE',
      'storeId': localStorage.getItem('cpid'),
      'title': values.fileName
    }
    $api.createAlbum(params).then(r => {
      message.success(r.message);
      if (r.code == '200') {
        initData()
      }
      setIsModalVisible(false);
      form.resetFields();
    })

  };

  const onFinishFailed = (errorInfo) => {
  };

  const closeCrrent = () => {

    props.setShowPicModal(false)
  }

  const [uploadPramas, setUploadPramas] = useState({})
  const uploads = {
    beforeUpload: file => {
      setUploadPramas({ 'faId': currentId })
      return new Promise((reslove, reject) => {
        if (currentId == -1) {
          message.error('无目录可以上传');
          return reject(false);
        }
        if (file.type.indexOf('pdf') > -1 && file.size / 1024 / 1024 > 2) {
          message.error('pdf格式的文件大小不能超过2M');
          return reject(false);
        }
        if ((file.type.indexOf('png') > -1 || file.type.indexOf('jpg') > -1 || file.type.indexOf('jpeg') > -1) && file.size / 1024 / 1024 > 1) {
          message.error('图片格式的文件大小不能超过1M');
          return reject(false);
        }
        let isImage = file.type.indexOf('jpeg') > -1 || file.type.indexOf('jpg') > -1 || file.type.indexOf('png') > -1
        if (isImage) {
          let reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => {
            const image = new Image();
            image.src = reader.result;
            image.onload = () => {
              setUploadPramas({ 'faId': currentId, 'width': image.width, 'height': image.height })
              reslove(true);
            }
          }
        } else {

          if (file.type.indexOf('vnd') > -1 || file.type.indexOf('doc') > -1 || file.type.indexOf('html') > -1 || file.type.indexOf('pdf') > -1 || file.type.indexOf('gif') > -1 || file.type.indexOf('xls') > -1 || file.type.indexOf('mp4') > -1 || file.type.indexOf('avi') > -1) {

            reslove(true);
          } else {
            message.error('暂不支持上传此格式');
            return reject(false);
          }
        }
      });
    },

    data: uploadPramas,
    name: 'file',
    action: '/api/api-fe/api-file/upload/aliyun',
    headers: {
      Authorization: localStorage.getItem('token')
    },
    maxCount: maxCount,
    onChange(info) {
      console.log('info', info)
      if (info.file.status !== 'uploading') {

      }
      if (info.file.status === 'done') {
        message.success(`${info.file.name} 上传成功.`);
        onChangePage(1, currentId)
      } else if (info.file.status === 'error') {
        message.error(`${info.file.name} 上传失败.`);
      }
    },
  };

  const [checkedList, setCheckedList] = useState([])

  const onChangeCheck = (v) => {
    if (!isMoreSelect && !isDelFile) {
      if (checkedList.length && checkedList[0].id === v.target.value.id) {
        setCheckedList([])
      } else {
        setCheckedList([v.target.value])
      }
    }
  }

  const onChangeGroup = (list) => {
    console.log('list---', list, maxCount)
    if (maxCount == 1 || (maxCount != 1 && list.length <= maxCount)) {
      if (isMoreSelect || isDelFile) {
        setCheckedList(list);
      }
    } else {
      message.warn(`最多可上传${maxCount}张`)
    }
  }

  const onOk = () => {//选择文件状态，点击确定操作
    //选中的内容：checkedList
    // console.log(checkedList)
    props.atChange && props.atChange(checkedList)
    // if(checkedList.length > 0){

    //   if(props.params.file == 'image' && props.params.width && props.params.height){
    //     const data = checkedList[0];
    //     setIsImageModelVisible(true)
    //   }else{
    //     /* 传递选择的文件 */
    //     props.atChange && props.atChange(checkedList)
    //   }
    // }
  }
  const delCheckedAllFile = () => {
    let ids = []
    if (checkedList.length) {
      confirm({
        title: '删除所选文件？',
        icon: <ExclamationCircleOutlined style={{ 'color': '#fff', 'backgroundColor': 'red', 'borderRadius': '50%' }} />,
        content: '该操作不可撤销',
        okText: '确认删除',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          for (let i = 0; i < checkedList.length; i++) {
            ids.push(checkedList[i].id)
          }
          $api.delFiles(ids).then(r => {
            if (r.code == '200') {
              message.success(r.message);
              setCurPage(1)
              onChangePage(1, currentId)
            }
          })
        },
        onCancel() {
        },
      });
    }
  }

  const [isImageModelVisible, setIsImageModelVisible] = useState(false);

  const handleImageOk = () => {
    setIsImageModelVisible(false);
  };

  const handleImageCancel = () => {
    setIsImageModelVisible(false);
  };

  const cropperRef = useRef(null);
  const onCrop = () => {
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;
    console.log(cropper.getCroppedCanvas().toDataURL());
  };

  const getHtmlHandle = (obj) => {
    let isFile = false
    let isLength = false
    if (props.params.file === 'image') {
      isFile = obj.contentType.indexOf('jpeg') > -1 || obj.contentType.indexOf('jpg') > -1 || obj.contentType.indexOf('png') > -1
      if (props.params.minWidth && props.params.minHeight) {
        if (isFile && ((props.params.minWidth && obj.width > props.params.minWidth) || (props.params.minHeight && obj.height > props.params.minHeight))) {
          isFile = false
          isLength = true;
        }
      } else {
        if (isFile && ((props.params.width && obj.width < props.params.width) || (props.params.height && obj.height < props.params.height))) {
          isFile = false
          isLength = true;
        }
      }
    } else {
      isFile = obj.contentType.indexOf(props.params.file) > -1
    }

    let isPic = obj.contentType.indexOf('jpeg') > -1 || obj.contentType.indexOf('jpg') > -1 || obj.contentType.indexOf('png') > -1
    let isdoc = obj.contentType.indexOf('doc') > -1
    let isHtml = obj.contentType.indexOf('html') > -1
    let isPdf = obj.contentType.indexOf('pdf') > -1
    let isXls = obj.contentType.indexOf('xls') > -1
    let isNorMal = !(isPic && isdoc && isHtml && isPdf && isXls)

    let picUrlHandle = () => {
      if (isPic) return handleSrcContent(obj.reqUrl)
      if (isdoc) return docPic
      if (isHtml) return htmlPic
      if (isPdf) return pdfPic
      if (isXls) return xlsPic
      if (isNorMal) return normalPic
    }
    let picUrl = picUrlHandle()
    if (isDelFile) {//删除状态
      return (
        <div className='fileUploadBox fileUploadDelHover'>
          <Checkbox style={{ 'position': 'absolute', 'left': '5px', 'top': '5px' }} name={obj.id} value={obj} onChange={onChangeCheck}></Checkbox>
          <div style={{ 'width': '100%', 'display': 'inline-block', 'border': '1px solid #fff' }}>
            <div style={{ 'width': '100%', 'height': '140px' }}>
              <img style={{ 'width': '100%', 'height': 'calc(100% - 20px)' }} src={picUrl} alt="" />
              <div style={{ 'width': '100%', 'height': '20px', 'background': 'rgba(0,0,0,0.50)', 'textAlign': 'center', 'color': '#fff' }}>{obj.width}*{obj.height}</div>
            </div>
            <div style={{ 'overflow': 'hidden', 'textOverflow': 'ellipsis', 'whiteSpace': 'nowrap' }}>{obj.title}</div>
          </div>
        </div>
      )
    } else {
      if (isFile) {//选择状态中，可选文件
        return (
          <div className='fileUploadBox fileUploadHover'>
            <Checkbox style={{ 'position': 'absolute', 'left': '5px', 'top': '5px' }} name={obj.id} value={obj} onChange={onChangeCheck}></Checkbox>
            <div style={{ 'width': '100%', 'display': 'inline-block', 'border': '1px solid #fff' }}>
              <div style={{ 'width': '100%', 'height': '140px' }}>
                <img style={{ 'width': '100%', 'height': 'calc(100% - 20px)' }} src={picUrl} alt="" />
                <div style={{ 'width': '100%', 'height': '20px', 'background': 'rgba(0,0,0,0.50)', 'textAlign': 'center', 'color': '#fff' }}>{obj.width}*{obj.height}</div>
              </div>
              <div style={{ 'overflow': 'hidden', 'textOverflow': 'ellipsis', 'whiteSpace': 'nowrap' }}>{obj.title}</div>
            </div>
          </div>
        )
      } else {//选择状态中，文件格式不符合要求
        return (
          <div className='fileUploadBox'>
            <div style={{ 'width': '100%', 'display': 'inline-block', 'border': '1px solid #fff' }}>
              <div style={{ 'width': '100%', 'height': '140px' }}>
                <img style={{ 'width': '100%', 'height': 'calc(100% - 20px)' }} src={picUrl} alt="" />
                <div style={{ 'width': '100%', 'height': '20px', 'background': 'rgba(0,0,0,0.50)', 'textAlign': 'center', 'color': '#fff' }}>{obj.width}*{obj.height}</div>
              </div>
              <div style={{ 'overflow': 'hidden', 'textOverflow': 'ellipsis', 'whiteSpace': 'nowrap' }}>{obj.title}</div>
            </div>
            <div style={{ 'width': '100%', 'height': '100%', 'position': 'absolute', 'top': '0', 'left': '0' }}>
              <div style={{ 'width': '100%', 'height': '100%', 'position': 'absolute', 'top': '0', 'left': '0', 'background': '#000', 'opacity': '0.6' }}></div>
              <div style={{ 'position': 'relative', 'zIndex': '2', 'top': 'calc(50% - 21px)', 'textAlign': 'center' }}>
                <img style={{ 'width': '21px' }} src={noSelect} />
                <div style={{ 'color': '#fff', 'textAlign': 'center' }}>{isLength ? '宽高不符合条件' : '格式不符合条件'}</div>
              </div>
            </div>
          </div>
        )
      }
    }
  }

  return (
    <>
      <div style={{ 'verticalAlign': 'middle', 'position': 'fixed', 'left': '0', 'top': '0', 'width': '100%', 'height': '100%', 'zIndex': '1000' }}>
        <div style={{ 'background': '#333', 'opacity': '0.5', 'verticalAlign': 'middle', 'position': 'absolute', 'top': '0', 'width': '100%', 'height': '100%' }}></div>
        <div style={{ 'width': '50%', 'minWidth': '575px', 'height': '60%', 'verticalAlign': 'middle', 'background': '#fff', 'zIndex': '1001', 'position': 'absolute', 'left': '25%', 'top': '20%', 'position': 'relative', 'backgroundColor': '#fff', 'backgroundClip': 'padding-box', 'border': '0', 'borderRadius': '2px', 'boxShadow': '0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d', 'pointerEvents': 'auto' }}>
          <div style={{ 'height': '55px', 'padding': '16px 24px', 'color': '#000000d9', 'background': '#F3F3F3', 'borderBottom': '1px solid rgba(0,0,0,.06)', 'borderRadius': '2px 2px 0 0' }}>
            <span style={{ 'color': '#000000' }}>存储空间</span><span style={{ 'color': '#95969A' }}>{modalTitle}</span>
            <span onClick={closeCrrent} style={{ 'display': 'inline-block', 'float': 'right', 'cursor': 'pointer' }}>
              <img src={closeIcon} alt="" />
            </span>
          </div>
          <div style={{ 'height': 'calc(100% - 110px)', 'padding': '24px', 'fontSize': '14px', 'lineHeight': '1.5715', 'wordWrap': 'break-word' }}>
            <div style={{ 'width': '200px', 'display': 'inline-block', 'height': '100%', 'overflow': 'auto' }}>
              <AuthButton style={{ 'marginBottom': '15px' }} onClick={showModal}>新建文件夹</AuthButton>
              <TreeRight
                dataSource={treeData}
                contextMenu={treeRightMenu}
                onSelect={handleSelect} >
                {renderTreeNodes(treeData)}
              </TreeRight>
            </div>
            <div style={{ 'position': 'relative', 'width': 'calc(100% - 210px)', 'display': 'inline-block', 'height': '100%', 'overflow': 'auto' }}>
              {pics.length ?
                <>
                  <Checkbox.Group value={checkedList} onChange={onChangeGroup}>
                    {pics.map((obj) => (
                      <div style={{ display: 'inline-block' }} key={obj.id}>
                        {getHtmlHandle(obj)}
                      </div>
                    )
                    )}
                  </Checkbox.Group>
                  <div style={{ 'clear': 'both' }}></div>
                  <Pagination size="small" current={curPage} total={totalPageSize} pageSize={pageSize} onChange={onChangePageNumber} />
                </> : showEmpty ? (<div style={{ 'position': 'relative', 'top': 'calc(50% - 90px)' }}>
                  <div style={{ 'textAlign': 'center' }}>
                    <img style={{ 'width': '150px', 'textAlign': 'center' }} src={nopicIcon} alt="" />
                    <div style={{ 'textAlign': 'center', 'marginTop': '20px' }}>
                      <span style={{ 'color': '#95969A' }}>暂无文件</span>
                    </div>
                  </div>
                </div>)
                  : null
              }
            </div>
          </div>
          <div style={{ 'height': '55px', 'padding': '10px 16px', 'background': '#FAFAFF', 'borderTop': '1px solid rgba(0,0,0,.06)', 'borderRadius': '0 0 2px 2px' }}>
            <span style={{ 'float': 'left', 'padding': '5px 0px' }}>
              <span style={{ 'color': '#95969A' }}>{fileSize}/{totalSize}</span>
              <a href='/system/materialCenter' target='' style={{ 'color': '#0052D9' }} > 素材中心</a>
            </span>
            {!isDelFile && (<span style={{ 'float': 'right' }}>
              <AuthButton onClick={() => { setIsDelFile(true); setCheckedList([]); }} style={{ 'marginRight': '10px' }} icon={<DeleteOutlined />} type="text">删除文件</AuthButton>
              <Upload {...uploads} showUploadList={false}
              >
                <AuthButton style={{ 'background': '#0052D9', 'color': '#FFFFFF' }} icon={<UploadOutlined />}>上传图片</AuthButton>
              </Upload>
              <AuthButton onClick={closeCrrent} style={{ 'marginLeft': '50px' }}>取消</AuthButton>
              <AuthButton onClick={onOk} style={{ 'marginLeft': '10px', 'background': '#0052D9', 'color': '#FFFFFF' }}>确定</AuthButton>
            </span>)
            }
            {isDelFile && (
              <span>
                <AuthButton onClick={() => {
                  setIsDelFile(false)
                  setCheckedList([]);
                }} style={{ 'marginLeft': '50px' }}>返回</AuthButton>
                <AuthButton buttonName="删除所选文件" style={{ 'marginLeft': '10px' }} type="primary" danger onClick={delCheckedAllFile}>删除所选文件</AuthButton>
              </span>
            )

            }
          </div>
        </div>
      </div>
      <Modal transitionName="" maskTransitionName=""
        title="新增文件夹" visible={isModalVisible} onOk={form.submit} onCancel={handleCancel}>
        <Form
          form={form}
          name="basic"
          labelCol={{ span: 8 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ pFile: '0', fileName: '' }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="上级文件夹"
            name="pFile"
            rules={[{ required: true, message: '请选择上级文件夹' }]}
          >
            <TreeSelect
              style={{ width: '100%' }}
              title={'value'}
              dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
              treeData={treeData}
              placeholder="请选择上级文件夹"
              treeDefaultExpandAll
              labelInValue={true}
              defaultValue='0'
              fieldNames={{ label: 'value', value: 'key' }}
              onSelect={onChangeFile}
            />
          </Form.Item>
          <Form.Item
            label="文件夹名称"
            name="fileName"
            rules={[{ required: true, message: '请输入文件夹名称' }]}
          >
            <Input onChange={onChangeName} placeholder="请输入名称" />
          </Form.Item>
        </Form>

      </Modal>

      <Modal
        title="图片裁切"
        closable={false}
        destroyOnClose={true}
        visible={isImageModelVisible}
        onOk={handleImageOk}
        onCancel={handleImageCancel}
        okText="确认"
        cancelText="取消"
      >
        <div className="cropperModal">

          <Cropper
            ref={cropperRef}
            src={'https://www.baidu.com/img/flexible/logo/pc/result.png'}//图片路径，即是base64的值，在Upload上传的时候获取到的
            style={{ height: 400, width: '100%' }}
            preview='.cropper-preview'
            className="company-cropper"
            viewMode={1} //定义cropper的视图模式
            zoomable={true} //是否允许放大图像
            aspectRatio={1} //image的纵横比1:1
            guides={false} //显示在裁剪框上方的虚线
            background={false} //是否显示背景的马赛克
            rotatable={true} //是否旋转
          />
          <div className='preview-button'>
            <div className="cropper-preview" style={{
              height: 100,
              width: '100%'
            }}></div>
          </div>

        </div>

      </Modal>
    </>
  );
}

export default FileUpload;