/* 路由鉴权 */
import { Navigate } from "react-router-dom"
function AuthenComp({ children }) {

  let myToken = localStorage.getItem('token')

  if (myToken) {
    return (<>{children}</>)
  } else {
    return (<Navigate to='/login' replace />)
  }
}

export {
  AuthenComp
}